<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品销售</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品销售</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-form  :model="addgoodssale" :rules="addgoodssaleRules"
                ref="addgoodssaleRef" label-width="100px">
        <!--搜索与添加区域-->
        <el-row :gutter="30">
          <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
            <el-button type="primary" icon="el-icon-search" @click="addGoodsListviable">添加商品</el-button>
          </el-col>

          <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
            <el-select v-model="addgoodssale.addclidID" placeholder="请选择客户等级"
                       @change="addNameSelectValue" clearable>
              <el-option
                  v-for="item in customerlevelList"
                  :key="item.cl_id"
                  :label="item.cl_name"
                  :value="item.cl_id">
              </el-option>
            </el-select>
          </el-col>

        </el-row>

        <el-form-item label-width="0px">
          <!--   用户列表区域   -->
          <el-table :data="addgoodssale.goodslist"  border stripe>
            <el-table-column type="index" ></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" min-width="70px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品名称" prop="g_name" min-width="80px" show-overflow-tooltip></el-table-column>
            <el-table-column label="商品规格" prop="ga_name" min-width="220px" >
              <template slot-scope="scope">
                <el-tooltip effect="dark" :content="scope.row.ga_name" placement="top" :enterable="true">
                  <div class="ga_name" >{{scope.row.ga_name}}</div>
                </el-tooltip>
                <el-select  v-model="scope.row.gad_id" placeholder="请选择"
                            clearable class="p_purchase_id" v-if="scope.row.goodsattrdate.length!==0">
                  <el-option
                      v-for="item in scope.row.goodsattrdate"
                      :key="item.gad_id"
                      :label="`${item.gad_start_date}~${item.gad_end_date}`"
                      :value="item.gad_id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间~结束时间" width="250px">-->

<!--            </el-table-column>-->
            <el-table-column label="单位" prop="gu_name" min-width="50px" show-overflow-tooltip></el-table-column>
            <el-table-column label="销售价" prop="ga_market_price" show-overflow-tooltip min-width="80px"></el-table-column>
            <el-table-column label="会员价" min-width="80px">
              <template slot-scope="scope">
                <div v-if="addgoodssale.addclidValue!=''">
<!--                  {{ (scope.row.ga_cost_price*2)}}-->
<!--                  {{scope.row.ga_market_price}}-->
                  {{scope.row.ga_me_price}}
                </div>
                <div v-else>{{scope.row.ga_market_price}}</div>
              </template>
            </el-table-column>
            <el-table-column label="最终销售价" min-width="130px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.si_price" @blur="isprice(scope.row)" @input="totalPrice"
                          @keyup.native="scope.row.si_price = money(scope.row.si_price)"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="ga_stock" min-width="70px"></el-table-column>
            <el-table-column label="数量" width="90px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.si_number" @blur="totalPrice" @input="isnumberBlur(scope.row)"></el-input>



              </template>
            </el-table-column>
            <el-table-column label="销售总价" min-width="90px">
              <template slot-scope="scope">
                <span >{{scope.row.si_total_price}}</span>
              </template>
            </el-table-column>
            <!--    保质期    -->
<!--            <el-table-column label="操作" width="100px">-->
<!--                        <template slot-scope="scope">-->
<!--              添加规格-->
<!--                          <el-button type="primary" size="mini"-->
<!--                                     @click="salegoods(scope.row.ga_id)">销售</el-button>-->
<!--                        </template>-->
<!--            </el-table-column>-->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="danger" size="mini" @click="delGoodsList(scope.row.ga_id)">删除</el-button>
                </template>
              </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="客户：" prop="c_id">
          <!--          <el-input v-model="addgoodssale.c_id"  class="p_remark"></el-input>-->
          <el-select v-model="addgoodssale.c_id" placeholder="请选择客户" @change="selectcustomer"
                     clearable class="p_purchase_id" @clear="clearctcustomer" v-if="value1">
            <el-option
                v-for="item in customerList"
                :key="item.c_id"
                :label="item.c_name"
                :value="item.c_id">
            </el-option>
          </el-select>
          <el-input v-else class="p_remark" v-model="addgoodssale.c_name"></el-input>
          <el-switch
              v-model="value1" @change="cChange"
              active-text="已有客户"
              inactive-text="添加客户">
          </el-switch>
        </el-form-item>
        <el-form-item label="客户联系人：" prop="cc_id">
          <!--          <el-input v-model="addgoodssale.cc_id"  class="p_remark"></el-input>-->
          <el-select v-model="addgoodssale.cc_id" placeholder="请选择客户联系人" v-if="value1"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in customerContactsList"
                :key="item.cc_id"
                :label="item.cc_name"
                :value="item.cc_id">
            </el-option>
          </el-select>
          <el-input v-else class="p_remark" v-model="addgoodssale.cc_name"></el-input>
        </el-form-item>
        <el-form-item label="销售员：" prop="s_sale">
          <!--          <el-input v-model="addgoodssale.s_sale"  class="p_remark"></el-input>-->
          <el-select v-model="addgoodssale.s_sale" placeholder="请选择销售员"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in memberList"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售日期：" prop="s_date">
          <!--          <el-input v-model="addgoodssale.s_date"  class="p_remark"></el-input>-->
          <el-date-picker v-model="addgoodssale.s_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="p_remark">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="送货人：" prop="s_deliverer">
          <!--          <el-input v-model="addgoodssale.s_deliverer"  class="p_remark"></el-input>-->
          <el-select v-model="addgoodssale.s_deliverer" placeholder="请选择送货人"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in memberList"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货人：" prop="s_consignee">
          <el-input v-model="addgoodssale.s_consignee"  class="p_remark"></el-input>

        </el-form-item>
        <el-form-item label="其他金额：" prop="s_other_price">
          <el-input v-model="addgoodssale.s_other_price"  class="p_remark"></el-input>
        </el-form-item>
        <el-form-item label="销售备注：" prop="s_remark">
<!--          <el-input v-model="addgoodssale.s_remark"  class="p_remark"></el-input>-->
          <textarea v-model="addgoodssale.s_remark" name="" id="" cols="30" rows="10"></textarea>
        </el-form-item>

        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <!--          <el-button @click="addgo">取消</el-button>-->

          <el-button type="primary" @click="salegoods" :loading="addloading">确定</el-button>
        </div>
      </el-form>
    </el-card>
    <el-dialog  title="选择商品" :visible.sync="addGoodsDialogVisible"
                width="70%">
      <el-row :gutter="30">
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="querInfo.g_name" clearable @clear="getGoodsList">
            <el-button slot="append" icon="el-icon-search" @click="getGoodsList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable placeholder="请选择商品分类"
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-col>
      </el-row>
      <el-table :data="Goodslist"  stripe @select-all="changeFun" @select="asd" ref="docTable">

        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope" >
            <div>{{scope.row.ga_name}}</div>
            <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name" width="120px"></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" width="120px"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return{
      addloading:false,
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        g_name:'',
        g_cid:''
      },
      total:0,
      Goodslist:[],
      //商品分类list
      categorieslList:[],
      //选中的父级分类的ID数组
      selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      value1:true,
      //客户等级
      customerlevelList:[],
      //客户
      customerList:[],
      //客户联系人
      customerContactsList:[],
      //用户列表
      memberList:[],
      //控制商品显示隐藏
      addGoodsDialogVisible:false,
      //提交form 表单
      addgoodssale:{
        goodslist:[],
        addclidValue:'',
        addclidID:'',
        c_id:'',
        cc_id:'',
        s_deliverer:'',
        s_sale:this.$store.state.m_id,
        s_consignee:'',
        s_other_price:'',
        s_date:this.$store.state.daydate,
        s_remark:'',
      },
      addgoodssaleRules:{
        we_remark: [
          {required: true, message: '请销售备注', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.getGoodsList()
    this.getcateList()
    this.getcustomerlevelList()
    this.getcustomerList()
    this.getmemberList()
  },
  methods: {
    //获取商品分类
    async getcateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.categorieslList = res.data
    },
    //获取客户级别
    async getcustomerlevelList() {
      const {data: res} = await this.$http.get('customerlevel/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.customerlevelList = res.data
    },
    //获取客户
    async getcustomerList() {
      const {data: res} = await this.$http.get('customer/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.customerList = res.data
    },
    //商品
    async getGoodsList(){
      const {data: res} = await this.$http.get('goods/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // res.data.data.forEach(irem=>{
      //   if (item.ga_status==1)
      // })
      // 把数据列表 赋值给catelist
      this.Goodslist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
    },
    //获取员工列表 member/getMainList
    async getmemberList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.memberList = res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList ()
    },
    //客户开关发生变化
    cChange(){
      if(this.value1==true){
        this.addgoodssale.c_name=''
        this.addgoodssale.cc_name=''
      }else{
        this.addgoodssale.c_id=''
        this.addgoodssale.cc_id=''
      }
    },
    //商品分类
    parentCateChanged() {
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      console.log(this.querInfo.g_cid)
      this.getGoodsList()
    },
    //select name发生变化时触发
    addNameSelectValue(){
      console.log(this.addgoodssale.addclidID)
      for (let i=0;i<this.customerlevelList.length;i++){
        if(this.customerlevelList[i].cl_id==this.addgoodssale.addclidID){
          this.addgoodssale.addclidValue=this.customerlevelList[i].cl_discount
        }
      }
      for(let j=0;j<this.addgoodssale.goodslist.length;j++){
        this.addgoodssale.goodslist[j].ga_me_price=(this.addgoodssale.goodslist[j].ga_cost_price*this.addgoodssale.addclidValue).toFixed(2)
      }
      // this.addcustomerForm.cl_id=this.addcustomerForm.addclidValue
    },
    //customer 客户选择时发生的变化
    async selectcustomer(){
      if (this.addgoodssale.c_id!=''){
        this.addgoodssale.cc_id=''
        this.customerContactsList=[]
        const {data: res} = await this.$http.get(`customer/getCustomerContactsList/${this.addgoodssale.c_id}`)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.customerContactsList=res.data
      }else{
        this.addgoodssale.cc_id=''
        this.customerContactsList=[]
      }

    },
    //清空客户选择
    clearctcustomer(){
      this.addgoodssale.cc_id=''
      this.customerContactsList=[]
    },
    addGoodsListviable(){
      this.addGoodsDialogVisible=true
    },
    //
    changeFun() {
      let count = 0;
      this.Goodslist.forEach((items)=>{
        this.addgoodssale.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addgoodssale.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },

    asd(selection, row) {
      let is_exists = true;
      this.addgoodssale.goodslist.forEach((item,index)=>{
        if (this.addgoodssale.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addgoodssale.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addgoodssale.goodslist.push(row)
        is_exists = true;
      }
      for(let j=0;j<this.addgoodssale.goodslist.length;j++){
        this.addgoodssale.goodslist[j].ga_me_price=(this.addgoodssale.goodslist[j].ga_cost_price*this.addgoodssale.addclidValue).toFixed(2)
      }
    },
    delGoodsList(id){
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.ga_id===id){
          this.addPurchaseForm.goodslist.splice(index,1)
        }
      })
    },
    //数量输入框
    isnumberBlur(row){
      if (isNaN(row.si_number)==true){
        row.si_number=1
      }else if(row.si_number<=0){
        row.si_number=0
      }
      // else if(row.si_number>row.ga_stock){
      //   row.si_number=row.ga_stock
      // }
      this.totalPrice()
    },
    //
    isprice(row){
      if (isNaN(row.si_price)==true){
        row.si_price=row.ga_market_price
      }else if(Number(row.si_price)<=0){
        row.si_price=row.ga_market_price
      }else if(Number(row.si_price)>0){
        console.log("123456789")
        row.si_price=Number(row.si_price).toFixed(2)
      }
      this.totalPrice()
    },
    totalPrice(){
      for (let i=0;i<this.addgoodssale.goodslist.length;i++){
        if (this.addgoodssale.goodslist[i].si_price!=undefined&&this.addgoodssale.goodslist[i].si_number!=undefined){
          // console.log(this.addgoodssale.goodslist[i].si_number)
          let totalprice=this.addgoodssale.goodslist[i].si_price*this.addgoodssale.goodslist[i].si_number
          this.addgoodssale.goodslist[i].si_total_price=totalprice.toFixed(2)
        }else{
          this.addgoodssale.goodslist[i].si_total_price=0
        }
      }
    },
    //销售
    salegoods(){
      this.$refs.addgoodssaleRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('sale/add', this.addgoodssale)
        this.addloading=true
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)

      })
    }
  },
}
</script>
<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
.el-cascader{
  width: 300px;
}
.el-select{
  width: 300px;
  margin-right: 30px;
}
.p_remark{
  width: 300px;
  margin-right: 30px;
}
.el-input__inner{
  width: 250px;
}
.ga_name{
  width:90%;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
textarea {
  width: 300px;
  height: 100px;
  color: #606266;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  outline: none;
}
</style>
